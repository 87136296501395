import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import EditIcon from '@material-ui/icons/Edit';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 520,
  },
}));

export default function DialogSelect({ event, editEvent }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [tags, setTags] = React.useState();
  const [tagsString, setTagsString] = React.useState();
  const [rank, setRank] = React.useState();
  const [title, setTitle] = React.useState();
  const [description, setDescription] = React.useState();
  const [imageAsFile, setImageAsFile] = React.useState();

  const maxInt = Math.pow(2, 53) - 1;

  const tagsErrorCondition = tagsString === '';
  const titleErrorCondition = title === '';
  const rankErrorCondition =
    rank === '' || (rank && !rank.match(/^\d*$/)) || parseInt(rank) > maxInt;

  const handleFileGetImage = (e) => {
    const image = e.target.files[0];
    setImageAsFile(image);
  };

  const handleRankChange = (e) => {
    setRank(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleTagsChange = (e) => {
    const value = e.target.value;
    setTagsString(value);
    let getTags = value.match(
      /(?:#)([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\\.(?!\\.))){0,28}(?:[A-Za-z0-9_]))?)/g
    );
    if (getTags) {
      getTags = getTags.map((x) => x.replace('#', '')).slice(0, 5);
    }
    setTags(getTags);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setTags(event.tags);
    setRank(String(event.rank));
    setTitle(event.title);
    setDescription(event.description);
    setTagsString(undefined)
  };

  const handleUpdate = () => {
    const updateData = {
      rank,
      title,
      description: description || '',
      tags,
    };
    editEvent(event.id, updateData, imageAsFile);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        variant='contained'
        color='default'
        className={classes.button}
        startIcon={<EditIcon />}
        onClick={handleClickOpen}
      >
        Edit
      </Button>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          Update <br />
          <small>{title}</small>
        </DialogTitle>
        <DialogContent>
          <form className={classes.container}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor='demo-dialog-native'></InputLabel>
              <Input
                type='file'
                onChange={handleFileGetImage}
                style={{ padding: '5px 0' }}
              ></Input>
            </FormControl>
          </form>
          <form className={classes.container}>
            <FormControl className={classes.formControl}>
              <InputLabel
                htmlFor='demo-dialog-native'
                error={rankErrorCondition}
              >
                Rank
              </InputLabel>
              <Input
                defaultValue={rank}
                onChange={handleRankChange}
                error={rankErrorCondition}
              ></Input>
              {!rank && rank === '' ? (
                <FormHelperText
                  id='component-error-text'
                  error={rankErrorCondition}
                >
                  Please provide a rank
                </FormHelperText>
              ) : (
                ''
              )}
              {rank && !rank.match(/^\d*$/) ? (
                <FormHelperText
                  id='component-error-text'
                  error={rankErrorCondition}
                >
                  Rank must be a number
                </FormHelperText>
              ) : (
                ''
              )}
              {parseInt(rank) > maxInt ? (
                <FormHelperText
                  id='component-error-text'
                  error={rankErrorCondition}
                >
                  Please provide a smaller number
                </FormHelperText>
              ) : (
                ''
              )}
            </FormControl>
          </form>
          <form className={classes.container}>
            <FormControl className={classes.formControl}>
              <InputLabel
                htmlFor='demo-dialog-native'
                error={titleErrorCondition}
              >
                Title
              </InputLabel>
              <Input
                defaultValue={title}
                onChange={handleTitleChange}
                error={titleErrorCondition}
              ></Input>
              {!title && title === '' ? (
                <FormHelperText
                  id='component-error-text'
                  error={titleErrorCondition}
                >
                  Please provide a title
                </FormHelperText>
              ) : (
                ''
              )}
            </FormControl>
          </form>
          <form className={classes.container}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor='demo-dialog-native'>Description</InputLabel>
              <Input
                defaultValue={description}
                onChange={handleDescriptionChange}
              ></Input>
            </FormControl>
          </form>
          <form className={classes.container}>
            <FormControl className={classes.formControl}>
              <InputLabel
                htmlFor='demo-dialog-native'
                style={{ fontSize: 12 }}
                error={tagsErrorCondition}
              >
                Tags (begin with '#')
              </InputLabel>
              <Input
                defaultValue={tags ? `#${tags.join('#')}` : ''}
                onChange={handleTagsChange}
                error={tagsErrorCondition}
              ></Input>
              {tagsErrorCondition && (
                <FormHelperText
                  id='component-error-text'
                  error={tagsErrorCondition}
                >
                  Please provide at least one tag
                </FormHelperText>
              )}
            </FormControl>
          </form>
          <div style={{ margin: '5px 5px 0 5px' }}>
            <p style={{ float: 'left', fontSize: 12 }}>
              {tags ? `#${tags.join(' #')}` : ''}
            </p>
            <p style={{ float: 'right', fontSize: 12 }}>
              {tags ? `${tags.length} tags` : '0/5'}
            </p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleUpdate} color='primary'>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
