import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const Pagination = ({ postsPerPage, totalPosts, paginate, currentPage }) => {
  const pageNumbers = [];
  const useStyles = makeStyles({
    pagination: {
      listStyleType: 'none',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'left'
    },
    page: {
      padding: '5px 10px',
      backgroundColor: 'rgba(255,255,255,0.3)',
      borderRadius: '5px',
      border: '1px solid #666',
      margin: '0 5px',
      cursor: 'pointer',
      color: '#666'
    },
    currentpage: {
      padding: '5px 10px',
      backgroundColor: '#FF7668',
      borderRadius: '5px',
      border: '1px solid #fff',
      margin: '0 5px',
      cursor: 'pointer',
      color: '#fff',
      fontWeight: 'bold'
    }
    })

    const classes = useStyles()

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', backgroundColor: '#949494', zIndex: 1 }}>
      <ul className={classes.pagination}>
        {pageNumbers.map(number => (
          <li onClick={() => paginate(number)} key={number} className={number === currentPage ? classes.currentpage : classes.page}>
              {number}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Pagination;