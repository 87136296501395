import React, { useState, useEffect } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from 'react-router-dom';

import Login from './Routes/Login/Login';
import ContentApproval from './Routes/ContentApproval/ContentApproval';
import ReviewRequests from './Routes/ReviewRequests/ReviewRequests';
import Events from './Routes/Events/Events';
import Users from './Routes/Users/Users';
import Header from './Components/UI/Header/Header';

function App() {
  const [accType, setAccType] = useState('');

  const logCheck = (acctype) => {
    localStorage.setItem('account', acctype);
    console.log(
      `%clogged in as ${acctype} from app.js`,
      'background-color:red;color:yellow;'
    );
  };

  useEffect(() => {
    setAccType(localStorage.getItem('account'));
    console.log(localStorage.getItem('account'));
  }, []);

  return (
    <Router>
      <div>
        <Header acctype={accType} />
        <Switch>
          <Route exact path='/'>
            <Redirect to='/login' />
          </Route>
          <Route path='/login'>
            <Login checklog={logCheck} />
          </Route>
          <Route path='/review-requests'>
            <ReviewRequests />
          </Route>
          <Route path='/content-approval'>
            <ContentApproval />
          </Route>
          <Route path='/events'>
            <Events />
          </Route>
          <Route path='/users'>
            <Users />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
