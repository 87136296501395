import React, { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import swal2 from 'sweetalert2';
import AddEvent from '../../Components/UI/EventActions/AddEvent';
import EditEvent from '../../Components/UI/EventActions/EditEvent';
import DeleteEvent from '../../Components/UI/EventActions/DeleteEvent';
import { db, storage } from '../../FirebaseConfig';
import firebase from 'firebase/app';
import TablePagination from '@material-ui/core/TablePagination';

const handleError = (error) => {
  const code = error.code;
  const message = error.message;
  const details = error.details;

  swal2.fire({
    icon: 'error',
    title: message,
    text: details,
  });

  console.log(code, message, details);
};

const uploadImage = async (imageAsFile, eventId) => {
  const task = await storage
    .ref()
    .child(`/postEvents/${eventId}/${imageAsFile.name}`)
    .put(imageAsFile);

  return await task.ref.getDownloadURL();
};

export default class Events extends React.Component {
  constructor() {
    super();
    this.state = {
      events: [],
      didUpdate: false,
      rowsPerPage: 25,
      page: 0,
    };
    this.db = db;
  }

  async componentDidMount() {
    this.getEvents();
    console.log('using effect');
  }

  handleChangePage(event, newPage) {
    this.setState({ page: newPage });
  }

  handleChangeRowsPerPage(event) {
    this.setState({ rowsPerPage: parseInt(event.target.value, 25) });
    this.setState({ page: 0 });
  }

  async getEvents() {
    try {
      let events = await this.db.collection('postEvents').orderBy('rank').get();
      events.forEach((event) => {
        const eventData = event.data();
        eventData.id = event.id;
        this.setState((previousState) => ({
          events: [...previousState.events, eventData],
        }));
        console.log(this.state.events);
      });
    } catch (error) {
      handleError(error);
    }
  }

  async addEvent(newEventData, imageAsFile) {
    console.log(
      '%cevent add User :',
      'background-color:red;color:black;',
      newEventData
    );

    const { rank, title, tags } = newEventData;

    try {
      if (
        !rank ||
        rank === '' ||
        !rank.match(/^\d*$/) ||
        rank > Math.pow(2, 53) - 1
      ) {
        throw new Error('Incorrect rank');
      }
      if (!title || title === '') {
        throw new Error('Please provide a title');
      }
      if (!tags || tags.length === 0) {
        throw new Error('Events require at least one tag');
      }

      newEventData.whenCreated = firebase.firestore.FieldValue.serverTimestamp();
      newEventData.whenUpdated = newEventData.whenCreated;
      newEventData.rank = Number(rank);

      const createdEvent = await db.collection('postEvents').add(newEventData);

      if (imageAsFile && imageAsFile !== '') {
        const eventId = createdEvent.id;

        const imageUrl = await uploadImage(imageAsFile, eventId);

        const imageData = {
          whenUpdated: newEventData.whenCreated,
          backgroundUrlSrc: {
            large: imageUrl,
            medium: imageUrl,
            small: imageUrl,
          },
        };
        await db
          .collection('postEvents')
          .doc(eventId)
          .set(imageData, { merge: true });
      }

      window.location.reload();
    } catch (error) {
      handleError(error);
    }
  }

  async editEvent(eventId, updateData, imageAsFile) {
    console.log(
      '%cevent update data :',
      'background-color:red;color:black;',
      updateData
    );

    const { rank, title, tags } = updateData;

    try {
      if (
        !rank ||
        rank === '' ||
        !rank.match(/^\d*$/) ||
        rank > Math.pow(2, 53) - 1
      ) {
        throw new Error('Incorrect rank');
      }
      if (!title || title === '') {
        throw new Error('Please provide a title');
      }
      if (!tags || tags.length === 0) {
        throw new Error('Events require at least one tag');
      }

      if (imageAsFile && imageAsFile !== '') {
        const imageUrl = await uploadImage(imageAsFile, eventId);

        updateData.backgroundUrlSrc = {
          large: imageUrl,
          medium: imageUrl,
          small: imageUrl,
        };
      }

      updateData.whenUpdated = firebase.firestore.FieldValue.serverTimestamp();
      updateData.rank = Number(rank);

      await db
        .collection('postEvents')
        .doc(eventId)
        .set(updateData, { merge: true });

      window.location.reload();
    } catch (error) {
      handleError(error);
    }
  }

  async removeEvent(event) {
    try {
      console.log('so long', event.title);
      const eventId = event.id;
      await db.collection('postEvents').doc(eventId).delete();

      const storageRef = storage.ref();
      const itemsRef = await storageRef
        .child(`/postEvents/${eventId}`)
        .listAll();
      itemsRef.items.forEach(async (item) => {
        await storageRef.child(`/postEvents/${eventId}/${item.name}`).delete();
      });

      window.location.reload();
    } catch (error) {
      handleError(error);
    }
  }

  render() {
    return (
      <Container style={{ paddingTop: '120px' }}>
        <TableContainer component={Paper}>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>
                  <h3>Rank</h3>
                </TableCell>
                <TableCell>
                  <h3>Name</h3>
                </TableCell>
                <TableCell>
                  <h3>Tags</h3>
                </TableCell>
                <TableCell>
                  <h3>Description</h3>
                </TableCell>
                <TableCell align='right'>
                  <AddEvent addEvent={this.addEvent} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(this.state.rowsPerPage > 0
                ? this.state.events.slice(
                    this.state.page * this.state.rowsPerPage,
                    this.state.page * this.state.rowsPerPage +
                      this.state.rowsPerPage
                  )
                : this.state.events
              ).map((event) => (
                <TableRow key={event.id}>
                  <TableCell align='center'>{event.rank}</TableCell>
                  <TableCell align='left'>{event.title}</TableCell>
                  <TableCell align='left'>
                    {event.tags ? `#${event.tags.join(', #')}` : ''}
                  </TableCell>
                  <TableCell align='left' style={{ width: '400px' }}>
                    {event.description}
                  </TableCell>
                  <TableCell align='right'>
                    <EditEvent
                      event={event}
                      editEvent={this.editEvent}
                      style={{ margin: '0 10px' }}
                    />
                    <DeleteEvent
                      event={event}
                      removeEvent={this.removeEvent}
                      style={{ margin: '0 10px' }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={this.state.events.length}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onChangePage={this.handleChangePage.bind(this)}
          onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
        />
      </Container>
    );
  }
}
