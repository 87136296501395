import React from 'react';
import firebase from 'firebase';
import { auth } from '../../FirebaseConfig';
import CircularProgress from '@material-ui/core/CircularProgress';

let firebaseui = require('firebaseui')

export default class Login extends React.Component {
  constructor(props) {
    super(props)
    this.ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
  }

  render() {
    const loginCheck = (acctype) => {
      this.props.checklog(acctype)
    }

    var uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: function(authResult, redirectUrl) {
          auth.currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            // Confirm the user is an Admin or Moderator.
            if (!!idTokenResult.claims.moderator) {
              loginCheck('moderator')
              return true;
              
            } else if (!!idTokenResult.claims.admin) {
              loginCheck('admin')
              return true;
            } else {
              // Show regular user UI.
              auth.signOut().then(function() {
      
                window.location.href= '/login'
          
              }).catch(function(error) {
                console.log("Something went wrong: ", error)
              });
              alert('Insufficient Permissions')
            }
          })
          .catch((error) => {
            console.log(error);
          });
          // User successfully signed in.
          // Return type determines whether we continue the redirect automatically
          // or whether we leave that to developer to handle.
          return true;
        },
        uiShown: function() {
          // The widget is rendered.
          // Hide the loader.
          document.getElementById('loader').style.display = 'none';
        }
      },
      // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
      signInFlow: 'popup',
      //signInSuccessUrl: 'https://keep-or-toss-dev.web.app/content-approval',
      signInSuccessUrl: '/content-approval',
      signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID
      ],
      // Terms of service url.
      tosUrl: '<your-tos-url>',
      // Privacy policy url.
      privacyPolicyUrl: '<your-privacy-policy-url>'
    }
    this.ui.start('#firebaseui-auth-container', uiConfig);
    return <React.Fragment>
      <div style={{ marginTop: '15%', paddingTop: '100px'}} id="firebaseui-auth-container"></div>
      <div id="loader" style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}><CircularProgress color="secondary" /></div>
    </React.Fragment>
  }

}