import React, {useState} from 'react'
import Button from '@material-ui/core/Button';
import { db } from '../../../FirebaseConfig';

export default class ReportCard extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
    this.state = {
      dateFormat: ''
    }

    this.db = db
    
  }

  async componentDidMount() {

    // get user info 
    let reportInfo = await this.db.collection('posts').doc(`${this.props.parent}`).collection('reports').doc(`${this.props.id}`).get()
    this.setState({ userId : reportInfo.ref.id })
  }

  updateReportStatus(e, v) {

    //e.target

    // if a report is valid, no need to go through the rest of the posts, reject it immediately 
    if(v === 'valid') {
      
      this.db.collection('posts').doc(`${this.props.parent}`).update({
        approvalStatus: 'rejected',
        whenUpdated: db.FieldValue.serverTimestamp()
      })
      
    }
    
    this.db.collection('posts').doc(`${this.props.parent}`).collection('reports').doc(`${this.props.id}`).update({
                reviewStatus: v,
                whenUpdated: db.FieldValue.serverTimestamp()
    });

  } 

  render() {
    return <React.Fragment>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: '90%',
        backgroundColor: '#f2f2f2',
        padding: '15px 20px',
        alignItems:'flex-start',
        boxSizing: 'border-box',
        boxShadow: '3px 3px 5px 0px rgba(0,0,0,0.17)',
        margin: '10px 0'
      }}>
        <div style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          flex: 1,
          justifyContent: 'space-between'
        }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
           <div style={{color: '#333',
              margin: '5px 0 5px 0',
              fontWeight: 'bold',
              fontSize: '12px'}}>
                Reported By: 
              

           </div>
            <div style={{
              color: '#333',
              margin: '5px 0 5px 0',
              fontWeight: 'bold',
              fontSize: '12px',
              paddingLeft: '2px'
            }}> {this.state && this.state.userId} </div>
          </div>
          <div style={{
            textTransform: 'uppercase',
            fontSize: '10px',
            fontWeight: 'bold'
          }}>{this.state && this.state.dateFormat}</div>
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          flex: 1,
          justifyContent: 'space-between',
          padding: '10px 0 20px 0'
        }}>
          <span>Reason: <span style={{
            textTransform: 'uppercase',
            fontWeight: 'bold',
          }}>{this.props.report.category}</span></span> 
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          width: '100%'
        }}>
          <Button onClick={(e) => this.updateReportStatus(e, 'valid')} variant="contained">Valid</Button>
          <Button onClick={(e) => this.updateReportStatus(e, 'invalid')} variant="contained">Invalid</Button>
        </div>
        
    </div>
    </React.Fragment>
  }
}
