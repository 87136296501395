import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';


  const useStyles = makeStyles({
    cardContainer: {
      
    },
    cardImage: {
      
    },
    cardOverlay: {
      backgroundColor: 'rgba(0,0,0,0)',
      display: 'flex',
      flex: 1,
      width: '100%',
      height: '100%',
      position: 'absolute',
      transition: 'all 0.2s ease-in'
    },
    cardFront: {
      
    },
    buttonIcon: {
      color: '#fff'
    },
    small: {
      width: 30,
      height: 30,
      border: '1px solid rgb(255,118,104)',
      boxShadow: '0px 0px 5px 2px rgba(255,119,104,1)'
    },
    username: {
      color: '#fff',
      margin: '5px 0 5px 0',
      paddingBottom: '5px',
      borderBottom: '1px solid #666'
    },
    backList: {
      fontSize: '10px',
      margin: '4px 0'
    }
  });
  

  let postDate = new Date().toDateString().slice(0, 59)

export default class ReviewCard extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  componentDidMount() {


  }

  render() {

    console.log(this.props)

    return <React.Fragment>
      <div style= {{ position: 'relative' }} onClick={this.props.onClick}>
        <div style={{
          width: '159px',
          height: '196px',
          margin: '20px 10px',
          overflow: 'visible',
          position: 'relative',
          transformStyle: 'preserve-3d',
          transition: 'transform 1.0s cubic-bezier(0,.75,.25,1)',
          cursor: 'pointer'
        }}>
          <div style={{
            boxSizing: 'border-box',
            border: '4px solid #fff',
            overflow: 'hidden',
            display: 'flex',
            flex: 1,
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            backfaceVisibility: 'hidden'
          }}>
            <img style={{
              width: 'auto',
              height: '100%',
              boxShadow: '0px 2px 12px 0px rgba(0,0,0,0.8)',
              backfaceVisibility: 'hidden',
              transform: 'rotateY(0deg)'
            }} src={this.props.post.postUrlSrc.medium} alt="" />
          </div>
        </div>
      </div>
    </React.Fragment>
  }
}
