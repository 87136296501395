import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { auth } from '../../../FirebaseConfig';
import Logo from '../logo.png'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';

import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  nav: {
    position: 'fixed',
    top: 0,
    width: '100%',
    backgroundColor: '#333',
    color: '#eee',
    borderBottom: '4px solid rgb(255,118,104)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 3
  },
  navul: {
    listStyleType: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    margin: 0
  },
  small: {
    width: 40,
    height: 40,
    marginRight: '20px',
    cursor: 'pointer'
  },
  navlist: {
    alignItems: 'center',
    padding: '0 20px'
  },
  navitem: {
    color: '#E9E3D9',
    textDecoration: 'none',
    cursor: 'pointer',
    transition: 'all 0.2s ease-in'
  },
  logo: {
    backgroundImage: `url(${Logo})`,
    width: 40,
    height: 40,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    alignItems: 'center',
    marginLeft: 20
  },
  dropdown: {
    color: '#333333',
    textDecoration: 'none'
  }
});


const Header = ({ acctype }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState(0);
  const [activeLink, setActiveLink] = React.useState('home')

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  const logout = () => {

    auth.signOut().then(function() {
      
      window.location.href= '/login'

    }).catch(function(error) {
      console.log("Something went wrong: ", error)
    });

  }
  


  return (
    
    <nav className={classes.nav}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: '#fff', textDecoration: 'none', fontSize: 22 }}>
        <div className={classes.logo} />
        <span style={{ margin: '0 10px' }}>Portal<span style={{ color: 'rgb(255,119,103)' }}>.</span></span>
      </div>
      <ul className={classes.navul}>
        <li className={classes.navlist}>
          <Link className={classes.navitem} to="/content-approval" 
                style={activeLink === 'content-approval' ? {color: 'rgb(255,118,104)'} : null} 
                onClick={() => setActiveLink('content-approval')}>
            <h5>Content Approval</h5>
          </Link>
        </li>
        <li className={classes.navlist}>
          <Link className={classes.navitem} 
                to="/review-requests" 
                style={activeLink === 'review-requests' ? {color: 'rgb(255,118,104)'} : null} 
                onClick={() => setActiveLink('review-requests')}>
            <h5>Review Requests</h5>
          </Link>
        </li>
        <li className={classes.navlist}>
          <Link className={classes.navitem} 
                to="/events" 
                style={activeLink === 'events' ? {color: 'rgb(255,118,104)'} : null} 
                onClick={() => setActiveLink('events')}>
            <h5>Events</h5>
          </Link>
        </li>
        { acctype === 'admin' ? 
        <li className={classes.navlist}>
          <Link className={classes.navitem} 
                to="/users" 
                style={activeLink === 'users' ? {color: 'rgb(255,118,104)'} : null} 
                onClick={() => setActiveLink('users')}>
            <h5>Users</h5>
          </Link>
        </li> : null
        }
      </ul>
      <div>
        <Avatar className={classes.small} onClick={handleClick} />
         
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >

          <MenuItem onClick={handleClose}>
            <Link className={classes.dropdown} to="#" onClick={ logout}>Logout</Link>
          </MenuItem>
        </Menu>
      </div>
    </nav>
        
    
  )
}

export default Header
