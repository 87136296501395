import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import PersonAdd from '@material-ui/icons/PersonAdd';

const Adduser = ({ user, newrole }) => {
  const [open, setOpen] = React.useState(false);
  const [updatedRole, setUpdatedRole]= React.useState('moderator')
  const [addedUser, setAddedUser]= React.useState()

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleRoleChange = (event) => {
    setUpdatedRole(String(event.target.value));
  };

  const handleNewUser = (event) => {
    setAddedUser(String(event.target.value));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    newrole(addedUser, updatedRole)
    setOpen(false);
  };

  return (
    
      <div>
      <Button variant="outlined" color="primary" startIcon={<PersonAdd />} onClick={handleClickOpen} >
        Add User
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add User</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="name"
            onChange={handleNewUser}
            fullWidth
          />
          
          </DialogContent>
          <DialogContent>
          <InputLabel htmlFor="dialog-native">Role</InputLabel>
          <Select
                native
                value={updatedRole}
                onChange={handleRoleChange}
                input={<Input id="dialog-native" />}
              >
                <option aria-label="None" value="Role" />
                <option value={'moderator'}>Moderator</option>
                <option value={'admin'}>Administrator</option>
              </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Add User
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    
  )
}

export default Adduser
